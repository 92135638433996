// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@include mat.core();

$ic-primary: mat.define-palette(mat.$indigo-palette);
$ic-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ic-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fontFamily: "Noto Sans", "Helvetica Neue", sans-serif;
$kids-typography: mat.define-typography-config(
  $font-family: $fontFamily,
);
$ic-theme: mat.define-light-theme(
  (
    color: (
      primary: $ic-primary,
      accent: $ic-accent,
      warn: $ic-warn,
    ),
    typography: $kids-typography,
  )
);

// Alternatively, you can import and @include the theme mixins for each component

@include mat.all-component-themes($ic-theme);

// Material Variables
:root {
  --mdc-typography-button-font-size: 14px;
  --mdc-typography-button-font-weight: 600;
}

/* tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: $fontFamily !important;
}

@import "./assets/css/components/custom";

//shadow
.card-shadow {
  box-shadow:
    0px 0.22222px 0.66898px 0px rgba(0, 0, 0, 0.02),
    0px 0.97778px 1.38519px 0px rgba(0, 0, 0, 0.03),
    0px 2.4px 2.7625px 0px rgba(0, 0, 0, 0.04),
    0px 4.62222px 5.41481px 0px rgba(0, 0, 0, 0.04),
    0px 7.77778px 9.95602px 0px rgba(0, 0, 0, 0.05),
    0px 12px 17px 0px rgba(0, 0, 0, 0.07) !important;
}

.plan-card {
  @apply bg-white rounded-lg border border-stone-300;

  .plan-sub-heading {
    @apply bg-gray-light;
  }

  &:hover,
  &.active {
    @apply bg-[#8E78B51F];

    .plan-sub-heading {
      @apply bg-[#E5E2EE];
    }

    .mat-mdc-outlined-button.mat-unthemed {
      background: #554176;
      color: #fff;
    }
  }

  .mat-mdc-outlined-button.mat-unthemed {
    background: #f1eef6;
    border-color: #f1eef6;
    color: #5f4c7e;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    cursor: default;
  }
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5 {
  font-family: $fontFamily;
}

.feat-option {
  ul {
    // list-style: square inside url("./assets/images/icons/check-icon.svg");
    padding: 0;
    margin: 0;

    li {
      @apply text-base text-black-lighter;
      padding-left: 32px;
      line-height: 24px;
      margin-bottom: 8px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        background: url("./assets/images/icons/check-icon.svg") no-repeat;
        top: 0;
        left: 0;
      }
    }
  }
}

.chatContent {
  p {
    margin-bottom: 24px;
  }

  code {
    p {
      margin-bottom: 0px;
    }
  }

  ol,
  ul {
    li {
      margin-bottom: 24px;
    }
  }
}

.markdown-container {
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  h1 {
    font-size: 1.625rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 1.5rem 0 0.5rem;
  }

  h3 {
    font-size: 1.375rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    padding-left: 2rem;
    list-style-type: initial;

    li {
      margin-bottom: 0.5rem;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li ul,
  li ul {
    margin-left: 2rem;
  }

  pre {
    padding: 0px 0.5rem;
    max-width: 800px;
    white-space: pre-wrap;
  }

  code {
    font-family:
      Consolas,
      Monaco,
      Andale Mono,
      monospace;
    line-height: 1.5;
    font-size: 0.875rem;
  }

  blockquote {
    margin: 1rem 2rem;

    p {
      color: #666;
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid #c8c8c8;
    margin-bottom: 0.5rem;

    td,
    th {
      padding: 0.25rem 0.5rem;
      border: 1px solid #c8c8c8;
    }
  }

  &.is-loading {
    // Fix flicker issue
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: unset;
      font-weight: unset;
      margin: unset;
      padding: unset;
      line-height: unset;
    }
  }
}

.limit-reached {
  position: relative;
}

.limit-reached::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #f2c94c;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  height: 60vh !important;
}

div.mat-expansion-panel-body {
  padding: 0 0 4px 0 !important;
}

.share-brain-box-word-wrap {
  word-wrap: break-word;
}

.hide-chatbot #chat-button,
.hide-chatbot #floating-chat {
  display: none !important;
}

#floating-chat {
  user-select: none !important;
  -webkit-user-select: none !important;
}

// this because font-bold is not working from Tailwind
.rezolve-font-bold {
  font-weight: 700;
}

.rezolve-dm-mono {
  font-family: "DM Mono";
}

app-chat-window app-header .logo-wrap a {
  display: none !important;
}

mat-dialog-container {
  font-family: $fontFamily !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .markdown-container {
    table {
      display: block;
      overflow-x: auto;
      width: 100%;
      border: none;
      td,
      tr,
      th {
        white-space: nowrap;
        width: auto;
        overflow: hidden;
      }
    }
  }
}

#splash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: white;
  display: flex;
  flex-direction: column;
}

body.loaded #splash {
  display: none;
}

.logo-wrap {
  img {
    max-height: 50px;
  }
}

.loader {
  display: inline-block;
  position: relative;
  background: rgb(239, 241, 246);
  overflow: hidden;
}

.loader::after {
  content: "";
  width: 30%;
  height: 100%;
  background: linear-gradient(to right, transparent, #ffffff 50%, transparent);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animationLoader 1s linear infinite;
}

@keyframes animationLoader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
