$mobileScreen: 768px;
$tabletScreen: 1023px;

:root {
  --colorBlue: #2653d9;
}

$lightGrayColor: rgba(185, 185, 185, 0.24);

.mdc-text-field--outlined {
  height: 52px !important;
  overflow: visible;
}

.input-space-0 {
  .mat-mdc-form-field-bottom-align::before {
    height: 0px !important;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}

.mat-form-field-appearance-outline {
  .mdc-text-field--outlined {
    // height: 52px;
    // overflow: visible;
    background: #fff;
    border-radius: 8px;
  }

  .mat-mdc-form-field-infix {
    min-height: 52px;
  }

  // .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input{
  //     color:red;
  // }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
  }

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: 10px;
  }

  //lable default color
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: rgba(33, 33, 33, 0.87);
  }

  //focus on label
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: rgba(33, 33, 33, 0.6);
  }

  //hover border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(33, 33, 33, 0.24);
  }

  //normal border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(33, 33, 33, 0.24);
  }

  //Focus border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: rgba(33, 33, 33, 0.24);
  }
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(33, 33, 33, 0.24) !important;
}

.mat-form-field-appearance-outline.edit-input {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #191919;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field--outlined {
    // height: 52px;
    // overflow: visible;
    background: #fff;
    border-radius: 8px;
    border: 3px solid rgba(38, 83, 217, 0.08);
  }

  .mat-mdc-form-field-infix {
    min-height: 48px;
  }

  // .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input{
  //     color:red;
  // }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 17px;
  }

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: 10px;
  }

  //lable default color
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: rgba(33, 33, 33, 0.87);
  }

  //hover border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: #2653d9;
  }

  //normal border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(33, 33, 33, 0.24);
  }

  //Focus border input
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #2653d9;
  }
}

.mat-mdc-form-field-icon-suffix {
  .mdc-button {
    margin: 0;
    margin-right: 10px;
    white-space: nowrap;
  }
}

// Buttons
.mdc-button {
  box-shadow: none !important;
  letter-spacing: normal;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2653d9;
}

.mat-mdc-raised-button:disabled {
  color: #ffffff !important;
  background-color: #8b98b9 !important;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #000000;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--colorBlue);
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(38, 83, 217, 0.54);
}

.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #2653d9;
}

.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #2653d9;
}

.mdc-button {
  --mdc-outlined-button-container-shape: 10px;
  --mdc-protected-button-container-shape: 10px;
  --mdc-text-button-container-shape: 10px;

  &.mat-mdc-button-base {
    height: 42px;
  }

  &.btn--lg {
    font-size: 16px;
    height: 56px;
    padding: 0 50px;
  }

  @media (max-width: $tabletScreen) {
    &.mat-mdc-button-base,
    &.btn--lg {
      font-size: 14px;
      height: 40px;
    }
  }

  &.btn--sm {
    font-size: 14px;
    height: 30px;
    padding: 0 10px;
    //   border-radius: 10px;
  }
}

.mdc-button .mdc-button__label {
  display: flex;
  gap: 10px;
}

.mdc-button__label2 {
  display: flex;
  gap: 5px;
}

.mat-mdc-outlined-button {
  &.mat-primary {
    --mdc-outlined-button-outline-color: #1877f2;
  }

  &.mat-accent {
    --mdc-outlined-button-outline-color: #de2617;
  }

  &.mat-warn {
    --mdc-outlined-button-outline-color: #de2617;
  }
}

/** remove drop arrow icons **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch {
  border-right: 0 !important;
}

.mat-mdc-menu-panel {
  border-radius: 6px !important;
  min-width: 194px !important;
  padding: 0 8px;
  max-width: 328px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15) !important;
}

.mat-mdc-menu-panel.user-menu {
  padding: 0;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

//radio button
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #4f71be !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #4f71be !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #4f71be !important;
}

.mat-mdc-radio-checked {
  background: #eaf7ff;
}

.flag-high-light:hover {
  background: #eaf7ff;
}

//checkbox
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
  border-color: #102776 !important;
  background-color: #102776 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: #102776 !important;
}

.update-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0.5rem;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .scrollable-dialog {
    width: 90% !important;
  }
}

.icon-primary {
  svg {
    path {
      fill: #2653d9;
    }
  }
}

// snackbar
.red-snackbar.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #f44336;
  color: white;
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: #b9f6ca;
    --mat-mdc-snack-bar-button-color: #b9f6ca;
    --mdc-snackbar-supporting-text-color: #000;
    .mdc-snackbar__surface {
      min-width: auto !important;
    }
  }
  &.green-snackbar-bottom {
    --mdc-snackbar-container-color: #b9f6ca;
    --mat-mdc-snack-bar-button-color: #b9f6ca;
    --mdc-snackbar-supporting-text-color: #000;
    padding-bottom: 70px;
  }
  &.blue-snackbar {
    --mdc-snackbar-container-color: #eff1fc;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.red-snackbar button {
  background-color: #f44336;
  color: white !important;
  border: none;
}

.brain-logo-home {
  @media (max-width: $mobileScreen) {
    img {
      width: 200px;
    }
  }
}

a {
  @apply text-primary;
}

.mat-mdc-slide-toggle.custom-toggle.mat-primary {
  --mdc-elevation-overlay-opacity: 1;

  .mdc-switch:enabled .mdc-switch__track::before,
  .mdc-switch:enabled .mdc-switch__track::after {
    background: #554176 !important;
    opacity: 0.38;
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon,
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: transparent !important;
  }

  .mdc-elevation-overlay {
    background: #554176 !important;
  }
}

//linear progress bar
.mat-mdc-progress-bar.brain-progress-bar {
  --mdc-linear-progress-track-height: 6px;
  --mdc-linear-progress-active-indicator-height: 6px;
  --mdc-linear-progress-active-indicator-color: #00c853;
  --mdc-linear-progress-track-color: white;
  --mdc-linear-progress-track-shape: 4px;

  .mdc-linear-progress__buffer {
    height: var(--mdc-linear-progress-track-height);
  }

  .mdc-linear-progress__bar {
    height: var(--mdc-linear-progress-active-indicator-height);
  }

  .mdc-linear-progress__bar-inner {
    border-color: var(--mdc-linear-progress-active-indicator-color);
    border-radius: 4px;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: var(--mdc-linear-progress-track-color);
  }
}

//Tabing
.mat-mdc-tab {
  --mat-tab-header-active-label-text-color: #2653d9;
  --mdc-tab-indicator-active-indicator-color: #2653d9;
  --mat-tab-header-inactive-label-text-color: #191919;
  --mat-tab-header-active-focus-label-text-color: #2653d9;
  --mat-tab-header-label-text-size: 15px;
  --mat-tab-header-label-text-weight: 600;
  font-size: var(--mat-tab-header-label-text-size);
  // letter-spacing: var(--mat-tab-header-label-text-tracking);
  line-height: var(--mat-tab-header-label-text-line-height);
  font-weight: var(--mat-tab-header-label-text-weight);
  padding-right: 16px !important;
  padding-left: 16px !important;

  .mdc-tab__text-label {
    color: var(--mat-tab-header-inactive-label-text);
  }

  .mdc-tab-indicator__content--underline {
    border-color: var(--mdc-tab-indicator-active-indicator-color) !important;
  }
}

.mat-mdc-tab-list {
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #c8c8c8;
  }
}

.mat-mdc-tab-body-wrapper {
  padding: 24px 0 !important;
}

.waitlist-alert {
  @apply bg-status-warning1 ml-2 rounded-full text-black-dark1 font-semibold text-sm text-center;
  line-height: 28px;
  display: flex;
  min-width: 28px;
  min-height: 28px;
  padding: 0 4px;
  letter-spacing: 0.67px;
}

.mat-mdc-paginator {
  --mat-paginator-container-background-color: transparent;
  background-color: var(--mat-paginator-container-background-color);
}
